import React from 'react';
import IUCNLogo from 'src/assets/images/dashboard/iucn.png';
import useProject from 'src/shared/hooks/useProject';
import { LinearLoader } from 'src/shared/components/loader/Loader';
import { getQuarterDateRange } from 'src/shared/utils/dateHelpers';
import MetricCard from 'src/shared/components/metric-card/MetricCard';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import { useAppSelector } from 'src/store';
import { useIucnMetricsQuery } from 'src/app/dashboard/state/metrics/metricsGraphSlice';
import { getCurrentDashboardUIState } from 'src/app/dashboard/state/dashboardGeneralSelectors';
import { filterByRedListStatus } from 'src/app/dashboard/components/metrics/metrics-table/metricsTableHelpers';

const IucnMetrics = () => {
    const { currentProjectId, currentProjectSelectedHabitatAssayType: habitatAssayType } = useProject();
    const uiState = useAppSelector(getCurrentDashboardUIState);

    const {
        iucnMetric: { selectedTimePoint },
    } = uiState;

    const { quarterStart, quarterEnd } = getQuarterDateRange(selectedTimePoint) || {};

    const { currentData, isFetching } = useIucnMetricsQuery(
        {
            projectId: currentProjectId || '',
            samples: {
                fromDate: quarterStart,
                toDate: quarterEnd,
                habitatAssay: habitatAssayType?.key || '',
            },
        },
        {
            skip: !currentProjectId || !habitatAssayType,
        }
    );

    if (!currentData || isFetching) {
        return (
            <>
                <LinearLoader />
            </>
        );
    }

    const { iucnMetrics: iucnMetricsData } = currentData;
    const { taxonomySummary } = iucnMetricsData;

    const iucnRedLists = filterByRedListStatus(taxonomySummary);

    return (
        <MetricCard
            header='IUCN Red List'
            content='Red List species detected'
            helpText={<QuestionButtonHelp type='api' placement='right' slug='iucn-status' />}
            total={iucnRedLists.length}
            logo={<img src={IUCNLogo} alt='Logo' style={{ marginRight: '5px' }} />}
        />
    );
};

export default IucnMetrics;
