import { TimeSeriesData } from 'src/shared/types/graph/chartData';
import {
    LegendData,
    LineStyleType,
    LegendStyleType,
    TickFormat,
    InteractivityPreference,
    TimeSeriesChartData,
} from 'src/shared/types/charts';
import moment from 'moment';

type habitatInsightsTrasformedChartData = {
    chartData: TimeSeriesChartData[];
    legendData: LegendData[];
    isDataWithBufferArea: boolean;
    isDataWithAnnualRange: boolean;
    tickslist: string[];
};

export const transformHabitatInsightsChartData = (
    input: TimeSeriesData[],
    bufferArea: string,
    annualRange: string,
    getColourForHabitatInsights: (type: string) => string
): habitatInsightsTrasformedChartData => {
    const output: habitatInsightsTrasformedChartData = {
        chartData: [],
        legendData: [],
        isDataWithBufferArea: false,
        isDataWithAnnualRange: false,
        tickslist: [],
    };

    input.forEach(inputObj => {
        inputObj.group.map(groupObj => {
            let outputObj = output.chartData.find(o => o.type === groupObj.type);
            if (!outputObj) {
                outputObj = {
                    type: groupObj.type,
                    data: [],
                    color: getColourForHabitatInsights(groupObj.type),
                    label: groupObj.type,
                    groupType: 'group' + groupObj.type,
                };
                output.chartData.push(outputObj);
                output.legendData.push({
                    label: groupObj.type,
                    type: groupObj.type,
                    color: getColourForHabitatInsights(groupObj.type),
                    groupType: 'group' + groupObj.type,
                    interactivityPreference: InteractivityPreference.GROUP_TYPE,
                });
            }

            const yearFromDateTime = moment(parseInt(inputObj.datetime)).format(TickFormat.YEAR_ONLY);

            outputObj.data.push({
                date: parseInt(yearFromDateTime),
                value: groupObj.value,
            });

            if (groupObj.boundary) {
                output.isDataWithBufferArea = true;
            }

            if (groupObj.boundary && bufferArea === 'show') {
                let bufferAreaObj = output.chartData.find(o => o.label === groupObj.type + ' Buffer Area');
                if (!bufferAreaObj) {
                    bufferAreaObj = {
                        type: 'Habitat Insights Buffer Area',
                        data: [],
                        color: getColourForHabitatInsights(groupObj.type),
                        label: groupObj.type + ' Buffer Area',
                        styleType: LineStyleType.DASHED,
                        groupType: 'group' + groupObj.type,
                    };
                    output.chartData.push(bufferAreaObj);
                }

                bufferAreaObj.data.push({
                    date: parseInt(yearFromDateTime),
                    value: groupObj.boundary,
                });
            }

            if (groupObj.annualRange) {
                output.isDataWithAnnualRange = true;
            }

            if (groupObj.annualRange && annualRange === 'show') {
                let annualRangeObj = output.chartData.find(o => o.label === groupObj.type + ' Annual Range');
                if (!annualRangeObj) {
                    annualRangeObj = {
                        type: 'Habitat Insights Annual Range',
                        data: [],
                        color: getColourForHabitatInsights(groupObj.type),
                        label: groupObj.type + ' Annual Range',
                        styleType: LineStyleType.AREA,
                    };
                    output.chartData.push(annualRangeObj);
                }

                annualRangeObj.data.push({
                    date: parseInt(yearFromDateTime),
                    value: [groupObj.annualRange.min, groupObj.annualRange.max],
                });
            }
        });
    });

    if (output.isDataWithBufferArea && bufferArea === 'show') {
        output.legendData.push({
            label: 'Buffer area',
            type: 'Habitat Insights Buffer Area',
            color: '#D9D9D9',
            styleType: LegendStyleType.DASHED,
            marginTop: '18px',
        });
    }

    if (output.isDataWithAnnualRange && annualRange === 'show') {
        output.legendData.push({
            label: 'Annual range',
            type: 'Habitat Insights Annual Range',
            color: '#C1C1C1',
            styleType: LegendStyleType.AREA,
            marginTop: '18px',
        });
    }

    if (output.chartData.length > 0) {
        output.tickslist = output.chartData[0].data.map(value => value.date.toString());
    }

    return output;
};
