import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useProject from 'src/shared/hooks/useProject';
import { LinearLoader } from 'src/shared/components/loader/Loader';
import { getQuarterDateRange } from 'src/shared/utils/dateHelpers';
import MetricCard from 'src/shared/components/metric-card/MetricCard';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import { useAppSelector } from 'src/store';
import { useIucnMetricsQuery } from 'src/app/dashboard/state/metrics/metricsGraphSlice';
import { getCurrentDashboardUIState } from 'src/app/dashboard/state/dashboardGeneralSelectors';
import { filterByGRIISList } from 'src/app/dashboard/components/metrics/metrics-table/metricsTableHelpers';

const InvasiveMetrics = () => {
    const { currentProjectId, currentProjectSelectedHabitatAssayType: habitatAssayType } = useProject();
    const uiState = useAppSelector(getCurrentDashboardUIState);

    const {
        iucnMetric: { selectedTimePoint },
    } = uiState;

    const { quarterStart, quarterEnd } = getQuarterDateRange(selectedTimePoint) || {};

    const { currentData, isFetching } = useIucnMetricsQuery(
        {
            projectId: currentProjectId || '',
            samples: {
                fromDate: quarterStart,
                toDate: quarterEnd,
                habitatAssay: habitatAssayType?.key || '',
            },
        },
        {
            skip: !currentProjectId || !habitatAssayType,
        }
    );

    if (!currentData || isFetching) {
        return (
            <>
                <LinearLoader />
            </>
        );
    }

    const { iucnMetrics: iucnMetricsData } = currentData;
    const { taxonomySummary } = iucnMetricsData;

    const invasiveLists = filterByGRIISList(taxonomySummary);

    return (
        <MetricCard
            header={'Invasive Species'}
            content='invasive species detected'
            helpText={<QuestionButtonHelp type='api' placement='right' slug='invasive-species' />}
            total={invasiveLists.length}
            logo={<InfoOutlinedIcon style={{ color: '#E42322', marginRight: '5px', rotate: '180deg' }} />}
        />
    );
};

export default InvasiveMetrics;
