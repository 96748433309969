import React from 'react';
import { TimeSeriesChartData } from 'src/shared/types/charts';
import { LegendStyleType, InteractivityPreference } from 'src/shared/types/charts';
import CircleIcon from '@mui/icons-material/Circle';
import styles from './CustomLegend.module.scss';
import LegendDashedIcon from './components/LegendDashedIcon';
import LegendAreaIcon from './components/LegendAreaIcon';

export type LegendDataProps = {
    label: string;
    type: string;
    color: string;
    styleType?: LegendStyleType;
    marginTop?: string;
    groupType?: string;
    interactivityPreference?: InteractivityPreference;
};

export interface CustomLegendProps {
    title: string;
    onClickHandler: (e: { value: string; hideByGroupType: boolean }) => void;
    hoverProps: { [key: string]: boolean | string | null };
    legendData: TimeSeriesChartData[] | LegendDataProps[];
    onMouseOverHandler: (e: { value: string }) => void;
    onMouseOutHandler: () => void;
    resetHanlder: () => void;
}

const CustomLegend = ({
    title,
    onClickHandler,
    hoverProps,
    legendData,
    onMouseOverHandler,
    onMouseOutHandler,
    resetHanlder,
}: CustomLegendProps) => {
    const isGroupTypePreferred = (legend: TimeSeriesChartData | LegendDataProps) => {
        return legend.interactivityPreference === InteractivityPreference.GROUP_TYPE;
    };

    const getLegendColor = (legend: TimeSeriesChartData | LegendDataProps) => {
        const legendType = isGroupTypePreferred(legend) && legend.groupType ? legend.groupType : legend.type;
        const legendHovered = hoverProps.hover === legendType;
        const legendNotHidden = (!hoverProps.hover === null && hoverProps[legendType]) === false;
        if (legendHovered || legendNotHidden || hoverProps[legendType]) {
            return legend.color;
        } else {
            return '#979797';
        }
    };

    const getReturnValue = (legend: TimeSeriesChartData | LegendDataProps) => {
        const hideByGroupType = isGroupTypePreferred(legend);
        const value = hideByGroupType && legend.groupType ? legend.groupType : legend.type;
        return { value, hideByGroupType };
    };

    return (
        <div className={styles.legendContainer}>
            <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>{title}</div>
            <ul style={{ listStyle: 'none', padding: 0 }}>
                {legendData.map((entry, index) => (
                    <li
                        key={`item-${index}`}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: entry.styleType && entry.styleType !== LegendStyleType.CIRCLE ? '18px' : '0',
                        }}
                        onClick={() => onClickHandler(getReturnValue(entry))}
                        onMouseOver={() => onMouseOverHandler({ value: entry.type })}
                        onMouseOut={onMouseOutHandler}
                    >
                        {entry.styleType === LegendStyleType.AREA && <LegendAreaIcon color={entry.color || '#979797'} />}
                        {entry.styleType === LegendStyleType.DASHED && <LegendDashedIcon color={entry.color || '#979797'} />}
                        {entry.styleType !== LegendStyleType.DASHED && entry.styleType !== LegendStyleType.AREA && (
                            <CircleIcon
                                style={{
                                    color: getLegendColor(entry),
                                    fontSize: '18px',
                                    opacity: hoverProps[entry.groupType || entry.type] ? 0.4 : 1,
                                }}
                            />
                        )}
                        &nbsp;
                        <span
                            data-testid={`legend-item-${entry.type}`}
                            className={hoverProps[entry.type] ? styles.hiddenLegendLabel : styles.legendLabel}
                        >
                            {entry.label.toUpperCase()}
                        </span>
                    </li>
                ))}
            </ul>
            {Object.values(hoverProps).some(Boolean) && !hoverProps.hover && (
                <div className={styles.reset}>
                    <a onClick={resetHanlder}>RESET</a>
                </div>
            )}
        </div>
    );
};

export default CustomLegend;
