import fetch from 'cross-fetch';

import LRUCache from 'lru-cache';

import JSONValue from 'src/shared/types/JSON';

export enum HTTPMethod {
    GET = 'GET',
    POST = 'POST',
    PATCH = 'PATCH',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

// Note: Currently does not conform to Core Data Modelling definition of an API error
export type APIError = {
    status: number;
    message: string | JSONValue;
};

export type FetchOptions = {
    method?: HTTPMethod;
    headers?: { [key: string]: string };
    body?: string; // stringified json
    preserveEndpoint?: boolean;
    noCache?: boolean;
    signal?: AbortSignal;
};

const defaultMethod = HTTPMethod.GET;
const defaultHeaders = { Accept: 'application/json' };

class ApiService {
    private cache: LRUCache<unknown, unknown>;
    private pendingCache: LRUCache<unknown, unknown>;

    public constructor() {
        this.cache = new LRUCache({
            max: 1000,
            maxSize: 5000,
            ttl: 60 * 60 * 1000,
        });

        this.pendingCache = new LRUCache({
            max: 1000,
            maxSize: 5000,
            ttl: 300,
        });
    }

    // method to enable easy testing
    public getFetch() {
        return fetch;
    }

    private buildFetchOptions(options: FetchOptions) {
        const headers: { [key: string]: string } = {
            ...defaultHeaders,
            ...options.headers,
        };
        if (
            options.method &&
            [HTTPMethod.POST, HTTPMethod.PUT, HTTPMethod.PATCH].includes(options.method) &&
            !headers.hasOwnProperty('Content-Type')
        ) {
            headers['Content-Type'] = 'application/json';
        }
        return {
            method: options.method || defaultMethod,
            headers: headers,
            body: options.body,
            signal: options.signal,
        };
    }

    public async fetch(endpoint: string, options: FetchOptions = {}) {
        const fetch = this.getFetch();
        const url = endpoint;

        if (!options.noCache) {
            const pendingItem = this.pendingCache.get(url);
            if (pendingItem) {
                // Wait for the pending request to finish
                await new Promise(resolve => {
                    const interval = setInterval(() => {
                        if (!this.pendingCache.get(url)) {
                            clearInterval(interval);
                            resolve(true);
                        }
                    }, 100);
                });
            }
            const cachedItem = this.cache.get(url);
            if (cachedItem) {
                return cachedItem;
            }
        }

        const fetchOptions = this.buildFetchOptions(options);

        try {
            if (!options.noCache) {
                this.pendingCache.set(url, true, {
                    size: 1,
                });
            }
            const response = await fetch(url, fetchOptions);
            if (!response.ok) {
                throw await this.handleError(response);
            }
            const processedResponse = await this.handleResponse(response, fetchOptions);
            if (!options.noCache) {
                this.cache.set(url, processedResponse, {
                    size: 1,
                });
            }
            return processedResponse;
        } catch (error) {
            this.cache.set(url, error, {
                size: 1,
            });
            if ((error as JSONValue).name !== 'AbortError') {
                throw error as JSONValue;
            }
        }
    }

    private async handleError(response: Response) {
        let errorJson;
        try {
            errorJson = await response.json();
        } finally {
            return {
                status: response.status,
                ...errorJson,
            };
        }
    }

    private async handleResponse(response: Response, options: FetchOptions) {
        if (options.headers && options.headers['Accept'] === 'application/json') {
            return await response.json();
        } else {
            return await response.text();
        }
    }
}

export default new ApiService();
