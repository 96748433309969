import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, type TypedUseSelectorHook } from 'react-redux';

import createRootReducer from './root/rootReducer';
import graphApiSlice from 'src/shared/state/api-slices/graphApiSlice';

import config from 'src/config';
import { DeepPartial } from './shared/types/deepPartial';

const rootReducer = createRootReducer();

const middleware = [graphApiSlice.middleware];

export default function getReduxStore() {
    const store = configureStore({
        reducer: rootReducer,
        middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middleware),
        devTools: config.isDevelopment,
    });

    return store;
}

export type AppStore = ReturnType<typeof getReduxStore>;
export type RootState = ReturnType<typeof rootReducer>;
export type PartialRootState = DeepPartial<RootState>;
export type AppDispatch = AppStore['dispatch'];
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
